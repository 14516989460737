
<style scoped>
.user-panel {
  margin: 0px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'130px'"
      >
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            v-model="formModel.goodsName"
            placeholder="请输入商品名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品提供方名称" prop="goodsSupply">
          <el-input
            v-model="formModel.goodsSupply"
            placeholder="请输入商品提供方名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图片" prop="goodsUrl">
          <el-upload
            class="avatar-uploader"
            name="photoFile"
            :action="uploadUrl"
            :show-file-list="false"
            :headers="headers"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="fileUrl" :src="fileUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="使用规则" prop="useRule">
          <el-input
            v-model="formModel.useRule"
            placeholder="请输入使用规则"
            type="textarea"
            :rows="3"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="兑换说明" prop="exchangeExplain">
          <el-input
            v-model="formModel.exchangeExplain"
            placeholder="请输入兑换说明"
            type="textarea"
            :rows="3"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="温馨提示" prop="cozyTips">
          <el-input
            v-model="formModel.cozyTips"
            placeholder="请输入温馨提示"
            type="textarea"
            :rows="3"
            style="width: 80%"
          ></el-input>
        </el-form-item>

        <el-form-item label="商品所需积分" prop="integralNum">
          <el-input-number
            v-model="formModel.integralNum"
            placeholder="请输入商品所需积分"
            style="width: 200px"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="总件数" prop="totalNum">
              <el-input-number
                v-model="formModel.totalNum"
                placeholder="请输入总件数"
                style="width: 200px"
                :min="1"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已兑换件数" prop="alreadyExchangeNum">
              <el-input-number
                v-model="formModel.alreadyExchangeNum"
                placeholder="请输入已兑换件数"
                style="width: 200px"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="商品状态" prop="goodsStatus">
          <el-switch
            v-model="formModel.goodsStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="上架"
            inactive-text="下架"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="关联用户" prop="userIds">
          <el-select
            v-model="formModel.userIds"
            filterable
            multiple
            placeholder="请选择"
            style="width:300px"
          >
            <el-option
              v-for="user in userList"
              :key="user.id"
              :label="user.realName"
              :value="user.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import integralGoodsInfoApi from "@/api/integral/integralGoodsInfo";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        goodsName: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
        goodsUrl: [
          { required: true, message: "商品图片不能为空", trigger: "blur" },
        ],
        integralNum: [
          { required: true, message: "商品所需积分不能为空", trigger: "blur" },
        ],
        totalNum: [
          { required: true, message: "总件数不能为空", trigger: "blur" },
        ],
        alreadyExchangeNum: [
          { required: true, message: "已兑换件数不能为空", trigger: "blur" },
        ],
        goodsSupply: [
          {
            required: true,
            message: "商品提供方名称不能为空",
            trigger: "blur",
          },
        ],
        goodsStatus: [
          {
            required: true,
            message: "商品状态（0：下架，1：上架）不能为空",
            trigger: "blur",
          },
        ],
        createBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        createTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        delFlag: [
          { required: true, message: "是否删除不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      //上传start
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "personInfo",
      },
      fileUrl: "",
      fileErtificateUrl: "",
      headers: {
        Authorization: getToken(),
      },
      //上传end
      userList:[],
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    integralGoodsInfoApi.integralUserList(formData).then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.userList = jsonData.data;
      }
    });

  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return integralGoodsInfoApi.add(self.formModel);
            } else {
              return integralGoodsInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    //上传start
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.goodsUrl = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //上传end
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return integralGoodsInfoApi.create();
      } else {
        return integralGoodsInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if (self.formModel.goodsStatus == null) {
            self.formModel.goodsStatus = 0;
          }

          let goodsUrl = self.formModel.goodsUrl;
          if (goodsUrl != null) {
            self.fileUrl =
              goodsUrl + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>