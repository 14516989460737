import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/integral/goodsInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/integral/goodsInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/integral/goodsInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/integral/goodsInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/integral/goodsInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/integral/goodsInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/integral/goodsInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function integralUserList(formData) {
  return request.post(constant.serverUrl + "/integral/goodsInfo/integralUserList", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,integralUserList
}